/* Portfolio */
.portfolio-wrapper {
  min-height: 100vh;
  padding-top: var(--header-height);
  padding-bottom: var(--footer-height);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.portfolio-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.flex-container-portfolio {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;
}

.portfolio-images {
  width: calc((100% - 80px) / 3);  /* 3 kép, 2 rés között */
  height: 500px;
  position: relative;
  overflow: hidden;
  opacity: 0.85;
  transition: 0.4s;
}

.portfolio-images:hover {
  opacity: 1;
}

.portfolio-images:hover figcaption {
  display: none;
}

.portfolio-images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Egyedi képpozicionálás */
.architektur-image {
  object-position: left center; 
}

.interieur-image {
  object-position: center center; 
}

.produkt-image {
  object-position: 5% center; 
}

.portfolio-images figcaption {
  display: block;
  color: var(--background-color);
  font-size: 1.6rem;
  font-weight: 350;
  position: absolute;
  top: 88%;
  left: 8%;
}

/* Ensure footer is only accessible by scrolling */
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}