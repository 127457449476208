.portfolio-navigation {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  margin-top: 40px;
}

.nav-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--secondary-color);
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: var(--primary-color);
}

.nav-link.prev {
  margin-right: auto;
}

.nav-link.next {
  margin-left: auto;
}

.arrow {
  width: 0.8em;
  height: 0.8em;
  border-top: 2px solid currentColor;
  border-right: 2px solid currentColor;
  display: inline-block;
}

.nav-link.prev .arrow {
  margin-right: 10px;
  transform: rotate(-135deg);
}

.nav-link.next .arrow {
  margin-left: 10px;
  transform: rotate(45deg);
}

.text {
  font-size: 1em;
}

body.dark-mode .nav-link {
  color: var(--dark-mode-secondary-color);
}

body.dark-mode .nav-link:hover {
  color: var(--dark-mode-primary-color);
}