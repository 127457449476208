
/* Dark mode styles */
body.dark-mode {
  background-color: var(--dark-mode-background-color);
  color: var(--dark-mode-primary-color);
}

body.dark-mode header {
  background-color: var(--dark-mode-background-color);
}

body.dark-mode h1,
body.dark-mode h2,
body.dark-mode .menu {
  color: var(--dark-mode-primary-color);
}

body.dark-mode p,
body.dark-mode a {
  color: var(--dark-mode-secondary-color);
}

body.dark-mode .portfolio-images figcaption {
  color: var(--background-color);
}

/* Dark mode toggle styles */
.dark-mode-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.toggle-checkbox {
  display: none;
}

.toggle-label {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}

.icon {
  width: 20px;
  height: 20px;
  position: absolute;
  transition: opacity 0.3s ease, fill 0.3s ease;
}

.sun-icon {
  opacity: 1;
}

.moon-icon {
  opacity: 0;
}

.toggle-checkbox:checked + .toggle-label .sun-icon {
  opacity: 0;
}

.toggle-checkbox:checked + .toggle-label .moon-icon {
  opacity: 1;
}

.sun-icon,
.moon-icon {
  fill: var(--primary-color);
}

body.dark-mode .sun-icon,
body.dark-mode .moon-icon {
  fill: var(--dark-mode-primary-color);
}
