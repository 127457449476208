/* slider.css */
.slider-container {
  height: calc(100vh - var(--header-height));
  width: 100%;
  margin-top: var(--header-height);
  position: relative;
  overflow: hidden;
}

.slick-slider,
.slick-list,
.slick-track,
.slick-slide > div {
  height: 100%;
}

.slick-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.slider-item {
  height: 100%;
  width: 100%;
  position: relative;
}

.slider-image {
  width: 100%;
  height: calc(100% - 40px);
  object-fit: cover;
}

.slider-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  color: var(--secondary-color);
  font-size: 0.8rem;
  font-weight: 250;
  text-align: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-caption.visible {
  opacity: 1;
}

body.dark-mode .slider-caption {
  color: var(--dark-mode-primary-color);
}

.slider-wrapper {
  position: relative;
}

.info-button {
  position: fixed;
  right: 25px;
  bottom: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--primary-color-light);
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 1rem;
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

body.dark-mode .info-button {
  background-color: var(--primary-color);
  color: var(--dark-mode-primary-color);
}

body.dark-mode .info-button:hover {
  background-color: var(--dark-mode-secondary-color);
  transform: scale(1.1);
}

.info-button:hover {
  background-color: var(--secondary-color);
  transform: scale(1.1);
}

.info-button.animate {
  animation: buttonPulse 1s ease-in-out 1;
}

@keyframes buttonPulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.slick-prev,
.slick-next {
  z-index: 1;
  width: 50px;
  height: 50px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.slick-prev {
  left: 20px;
}

.slick-next {
  right: 20px;
}

.slider-container:hover .slick-prev,
.slider-container:hover .slick-next {
  opacity: 1;
}

.slick-prev:before,
.slick-next:before {
  content: "";
  width: 20px;
  height: 20px;
  border-top: 2px solid var(--primary-color);
  border-right: 2px solid var(--primary-color);
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.slick-prev:before {
  transform: translate(-25%, -50%) rotate(-135deg);
}

.slick-next:before {
  transform: translate(-75%, -50%) rotate(45deg);
}

body.dark-mode .slick-prev:before,
body.dark-mode .slick-next:before {
  border-color: var(--dark-mode-secondary-color);
}

.slick-slide {
  transition: opacity 0.24s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.slick-active {
  opacity: 1;
}

/* Ensure footer is only accessible by scrolling */
body {
  padding-bottom: var(--footer-height);
}

footer {
  position: relative;
}