
@charset "utf-8";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
  font-display: swap;
  font-family: Inter;
  src: url(../fonts/Inter-VariableFont_slnt_wght.ttf) format("truetype");
}

/* Predefined Variables */
:root {
  --primary-color: rgb(102, 102, 102);
  --secondary-color: rgb(153, 153, 153);
  --primary-color-light: rgb(224, 224, 224);
  --background-color: rgb(255, 255, 255);
  --dark-mode-background-color: rgb(17, 17, 17);
  --dark-mode-primary-color: rgb(182, 182, 182);
  --dark-mode-secondary-color: rgb(155, 155, 155);
  --font-size-footer: 0.7rem;
  --font-size-caption: 2.4rem;
  --font-weight-caption: 300;
  --letter-spacing-caption: -0.12rem;
  --letter-spacing-footer: -0.03rem;
  --lineheight-p: 1.5rem;
  --header-height: 85px;
  --footer-height: 50px;
  --default-padding: 80px;
}


/* General Styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

html,
body {
  height: 100%;
  display: flex;
  flex-direction: column;
  font: 1.18rem Inter, sans-serif;
  scroll-behavior: smooth;
}

main {
  padding: 0 40px;
  flex-grow: 1;
}

h1,
h2,
.menu {
  color: var(--primary-color);
}

p,
a {
  color: var(--secondary-color);
  font-weight: 150;
  line-height: var(--lineheight-p);
}

a {
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}

h1,
h2 {
  font-size: var(--font-size-caption);
  font-weight: var(--font-weight-caption);
  letter-spacing: var(--letter-spacing-caption);
}

h3 {
  font-size: 1.6rem;
  font-weight: 200;
  letter-spacing: -0.08rem;
  color: var(--secondary-color);
}

h4 {
  font-size: 1.2rem;
  font-weight: 200;
  letter-spacing: -0.07rem;
  color: var(--secondary-color);
}

.italic {
  font-style: italic;
}

/* Text + Images Content */

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: var(--default-padding);
}

.flex-container:first-of-type {
  margin-top: calc(var(--header-height) + var(--default-padding) / 2);
}

.caption {
  flex-basis: 50%;
}

.content {
  flex-basis: 50%;
}

.flex-container img {
  max-width: 100%;
  height: auto;
  display: block;
}
