.accordion {
    margin: 1rem 0;
    padding: 0;
    list-style: none;
    border-top: 1px solid #e5e5e5;
  }
  
  .accordion-item {
    border-bottom: 1px solid #e5e5e5;
  }
  
  body.dark-mode .accordion {
    border-top: 0.9px solid var(--dark-mode-secondary-color);
  }
  
  body.dark-mode .accordion-item {
    border-bottom: 0.9px solid var(--dark-mode-secondary-color);
  }
  
  .accordion-thumb {
    margin: 0;
    padding: 0.8rem 0;
    cursor: pointer;
  }
  
  .accordion-thumb::before {
    content: "";
    display: inline-block;
    height: 10px;
    width: 10px;
    margin-right: 1rem;
    margin-left: 0.5rem;
    vertical-align: middle;
    border-right: 1px solid;
    border-bottom: 1px solid;
    transform: rotate(45deg);
    transition: transform 0.3s ease-out;
  }
  
  .accordion-item.is-active .accordion-thumb::before {
    transform: rotate(-135deg);
  }
  
  .accordion-panel {
    margin: 0;
    padding-bottom: 0.8rem;
    padding-left: 2rem;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }
  
  .accordion-item.is-active .accordion-panel {
    max-height: 1000px; /* Adjust this value based on your content */
    opacity: 1;
  }
  