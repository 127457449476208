.project p {
    font-size: 0.8rem;
    border-bottom: 0.9px solid var(--secondary-color);
    padding-bottom: 5px;
  }
  
  .project img {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .vertical-photo {
    max-height: 70vw;
  }
  