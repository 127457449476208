/* Header, Logo, Menu */
header {
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  padding: 0 30px;
  top: 0;
  z-index: 10;
  width: 100%;
  transition: transform 1s ease, opacity 1s ease;
  opacity: 1;
  background-color: transparent;
  /* background-color: var(--background-color); */
}

.header_up {
  transform: translateY(-100%);
  opacity: 0;
}

.logo img {
  height: 57px;
}

nav {
  display: flex;
  align-items: center;
}

nav ul {
  display: flex;
  gap: 20px;
  list-style: none;
}

.menu {
  margin-right: auto;
}

.menu a {
  text-decoration: none;
  color: var(--secondary-color);
  transition: color 0.3s ease, border-bottom-color 0.3s ease;
  padding-bottom: 0.1em;
  border-bottom: 1px solid transparent;
}

.menu a:hover,
.menu a.hovered,
.menu a.current {
  color: var(--primary-color);
  border-bottom-color: var(--primary-color);
}

/* Dropdown styles */
.portfolio-menu-item {
  position: relative;
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: -10px;
  background-color: var(--background-color);
  padding: 10px;
  z-index: 1000;
}

.portfolio-menu-item:hover .dropdown {
  display: block;
}

.dropdown ul {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin: 0;
}

.dropdown li {
  margin: 0;
  padding: 5px 0;
}

.dropdown li:first-child {
  padding-top: 0;
}

.dropdown li:last-child {
  padding-bottom: 0;
}

.dropdown a {
  color: var(--secondary-color);
  text-decoration: none;
  transition: color 0.3s ease, border-bottom-color 0.3s ease;
  white-space: nowrap;
  display: block;
  border-bottom: 1px solid transparent;
}

.dropdown a:hover {
  color: var(--primary-color);
  border-bottom-color: var(--primary-color);
}

/* Dark mode styles */
body.dark-mode .menu a {
  color: var(--dark-mode-secondary-color);
}

body.dark-mode .menu a:hover,
body.dark-mode .menu a.hovered,
body.dark-mode .menu a.current {
  color: var(--dark-mode-primary-color);
  border-bottom-color: var(--dark-mode-primary-color);
}

body.dark-mode .dropdown {
  background-color: var(--dark-mode-background-color);
}

body.dark-mode .dropdown a {
  color: var(--dark-mode-secondary-color);
}

body.dark-mode .dropdown a:hover {
  color: var(--dark-mode-primary-color);
  border-bottom-color: var(--dark-mode-primary-color);
}

/* Dark mode toggle styles */
.dark-mode-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.toggle-checkbox {
  display: none;
}

.toggle-label {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}

.icon {
  width: 20px;
  height: 20px;
  position: absolute;
  transition: opacity 0.3s ease, fill 0.3s ease;
}

.sun-icon {
  opacity: 1;
}

.moon-icon {
  opacity: 0;
}

.toggle-checkbox:checked + .toggle-label .sun-icon {
  opacity: 0;
}

.toggle-checkbox:checked + .toggle-label .moon-icon {
  opacity: 1;
}

.sun-icon,
.moon-icon {
  fill: var(--primary-color);
}

body.dark-mode .sun-icon,
body.dark-mode .moon-icon {
  fill: var(--dark-mode-primary-color);
}