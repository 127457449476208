/* Footer */
footer {
  height: var(--footer-height);
  font-size: var(--font-size-footer);
  letter-spacing: var(--letter-spacing-footer);
  text-align: center;
  flex-shrink: 0;
}

footer p {
  display: inline;
}

footer a {
  margin: 0 2px;
}
