.feedback blockquote {
    margin-bottom: 3rem;
    position: relative;
    padding-top: 2rem; 
  }
  
  .feedback blockquote::before {
    content: "\201C";
    position: absolute;
    left: 0;
    top: 0;
    font-family: "Times New Roman", Times, serif;
    font-size: 4em;
    color: var(--secondary-color);
    line-height: 1;
    opacity: 0.7;
  }
  
  .feedback blockquote p {
    font-style: italic;
    font-size: 1.1rem;
    line-height: 1.6;
    color: var(--secondary-color);
    position: relative;
    top: 1rem; 
  }
  
  .feedback .client {
    text-align: right;
    font-size: 0.9rem;
    margin-top: 1.5rem; 
    color: var(--secondary-color);
  }
  
  .feedback .client::before {
    content: "\2014 \00A0";
  }
  
  body.dark-mode .feedback blockquote::before {
    color: var(--dark-mode-secondary-color);
  }
  
  body.dark-mode .feedback blockquote p,
  body.dark-mode .feedback .client {
    color: var(--dark-mode-secondary-color);
  }